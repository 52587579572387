import { useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { tokenUpdated, useLazyAuthenticationQuery } from '@/store/slices/app';
import { rehydrateTokenFromLocalStorage } from '@/helpers/hydration';

export const useCurrentToken = () => {
  const token = useSelector((state) => state.app.token);
  return token;
};

export const useVerifySavedToken = () => {
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.app.isLoggedIn);
  const [trigger] = useLazyAuthenticationQuery({refetchOnReconnect: true});
  const token = rehydrateTokenFromLocalStorage();
  useLayoutEffect(()=> {
    if (token && !isLogin) {
      dispatch(tokenUpdated(token));
      trigger();
    }
  }, []);
};
