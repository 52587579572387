/* eslint-disable react/prop-types */
import { useState } from 'react';
import { toast } from 'react-toastify';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import { ADS_TYPES, REPORT_TYPES } from '@/constants/adsTypes';
import { useGetNavigationQuery, useUploadReportMutation } from '@/store/slices/app';

import { RESPONSE_STATUS } from './constants';
import StatusTitle from './StatusTitle';
import UploadOptions from './UploadOptions';

export default function FileUploader({ hubId, hubType, prevFile }) {
  const { refetch } = useGetNavigationQuery();
  const [uploadReport] = useUploadReportMutation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [responseData, setResponseData] = useState(null);

  function handleClose(_event, reason) {
    if (uploadFile && reason === 'backdropClick') return;
    setIsDialogOpen(false);
    setTimeout(() => {
      setResponseData(null);
      setUploadFile(null);
    }, 2000);
  }

  async function handleFileChange(e) {
    try {
      const file = e.target.files?.[0];
      if (!file) return;
      setUploadFile(file);

      const formData = new FormData();
      formData.append('reportFile', file);

      const res = await uploadReport({
        formData,
        hubId,
        reportType: REPORT_TYPES[hubType],
      });

      if (res.error) {
        toast.error('上傳檔案內容有誤，請參考「說明文件」');
        setUploadFile(null);
        return;
      }

      setResponseData(res.data);
      await refetch();
    } catch (error) {
      console.error(error);
      toast.error('上傳檔案內容有誤，請參考「說明文件」');
      setUploadFile(null);
    }
  }

  return (
    <>
      <Button
        endIcon={<AddCircleIcon />}
        onClick={() => setIsDialogOpen(true)}
        variant="contained"
      >
        {ADS_TYPES[hubType]}
      </Button>
      <Dialog fullWidth onClose={handleClose} open={isDialogOpen}>
        <DialogTitle
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          lineHeight={1.5}
          sx={{ padding: '0.5rem 1rem 0.5rem 1.5rem' }}
        >
          {ADS_TYPES[hubType]} 廣告成效匯入
          <IconButton color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ display: 'flex' }}>
          {uploadFile ? (
            <Box display="grid" gap={4} justifyItems="center" width="100%">
              <StatusTitle status={responseData?.status} />
              <Box
                display="flex"
                alignItems="center"
                gap={0.5}
                border="1px solid #ccc"
                borderRadius={2}
                padding={1}
              >
                <UploadFileIcon />
                <Typography component="span" sx={{ textWrap: 'balance' }}>
                  {uploadFile?.name || 'csv'}
                </Typography>
              </Box>
              {!responseData?.status && (
                <Box display="grid" gap={4}>
                  <LinearProgress />
                  <Typography>請等待檔案上傳完成再關閉</Typography>
                </Box>
              )}
              {responseData?.status === RESPONSE_STATUS.SUCCESS && (
                <Typography align="center">請於左側欄位查看報表</Typography>
              )}
              {responseData?.status === RESPONSE_STATUS.FAILED && (
                <>
                  <ul style={{ margin: 0, paddingInlineStart: '1.5rem' }}>
                    {JSON.parse(responseData.apiResponse)?.missingColumns?.map(
                      col => (
                        <li key={col}>{col}</li>
                      )
                    )}
                  </ul>
                  <Typography align="center" color="#E65100" fontWeight={500}>
                    已上傳成功，但欄位缺少將影響報表完整度
                  </Typography>
                </>
              )}
            </Box>
          ) : (
            <UploadOptions
              handleFileChange={handleFileChange}
              hubType={hubType}
              prevFile={prevFile}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
