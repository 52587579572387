import { createSlice } from '@reduxjs/toolkit';
import { appApi } from './api';
import {
  dehydrateTokenToLocalStorage,
  dehydrateRoleToLocalStorage,
  rehydrateRoleFromLocalStorage,
} from '@/helpers/hydration';
import constants from '@/constants/definition';
import { getRequestOptions } from '@/helpers/request';
import { getI18nMessageKey } from '@/helpers/message';
import { toast } from 'react-toastify';
import i18n from '@/i18n';
import { createCommonExtraReducers } from './extraReducers';



const initialState = {
  isLoggedIn: false,
  token: null,
  user: null,
  loading: 0, // 0 means nothing to load, > 0 means loading data
  loadingText: '',
  currentRoleId: null,
  currentRoleType: null
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    login(state, action) {
      state.isLoggedIn = true;
      state.user = action.payload;
    },
    tokenUpdated: (state, action) => {
      state.token = action.payload;
      dehydrateTokenToLocalStorage(action.payload);
    },
    currentRoleUpdated(state, action) {
      if (!state.user.roles?.length) {
        return;
      }
      let role = state.user.roles.find(role => role.roleId === action.payload) || state.user.roles[0];
      state.currentRoleId = role.roleId;
      state.token = role.accessToken.token;
      state.currentRoleType = role.roleType;
      dehydrateRoleToLocalStorage(action.payload);
      dehydrateTokenToLocalStorage(role.accessToken.token);
    },
    logout: () => {
      localStorage.removeItem('currentRole');
      localStorage.removeItem(constants.CDH_TOKEN_KEY);
      return initialState;
    }
  },
  extraReducers: (builder) => {
    createCommonExtraReducers(builder);
  },
});

const extendedApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ email, password }) => ({
        url: 'authenticate',
        method: 'POST',
        body: { email, password }
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const currentRoleIdx = 0;
          dispatch(login(data));
          dispatch(currentRoleUpdated(data.roles[currentRoleIdx].roleId));
        } catch (err) {
          dispatch(logout());
        }
      },
    }),
    register: builder.mutation({
      query: ({ email, password, contactName, companyName, areaCode, phoneNumber }) => ({
        url: 'event-register',
        method: 'POST',
        body: { email, password, contactName, companyName, areaCode, phoneNumber }
      }),
    }),
    uploadReport: builder.mutation({
      query: ({ reportType, hubId, formData }) => {
        return ({
        url: `report?reportType=${reportType}&hubId=${hubId}`,
        method: 'POST',
        body: formData
      })},
    }),
    getNavigation: builder.query({
      query: () => 'navigation-v2'
    }),
    getHub: builder.query({
      query: () => 'hub'
    }),
    updateHub: builder.mutation({
      query: ( hub ) => {
        return {
          url: 'hub',
          method: 'PUT',
          body: hub
        };
      },
    }),
    authentication: builder.query({
      query: () => 'authenticate',
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const roleId = rehydrateRoleFromLocalStorage();
          let currentRoleIdx = 0;
          if (roleId !== null) {
            const roleIndex = data.roles.findIndex((role) => role.roleId === roleId);
            roleIndex !== -1 && (currentRoleIdx = roleIndex);
          }
          dispatch(login(data));
          dispatch(currentRoleUpdated(data.roles[currentRoleIdx].roleId));
        } catch (err) {
          dispatch(logout());
        }
      },
    }),
  })
})

export const {
  login,
  logout,
  tokenUpdated,
  currentRoleUpdated
} = appSlice.actions;
export const {
  useLoginMutation,
  useRegisterMutation,
  useLazyAuthenticationQuery,
  useGetNavigationQuery,
  useGetHubQuery,
  useUpdateHubMutation,
  useUploadReportMutation,
} = extendedApi;

export default appSlice.reducer;