/* eslint-disable react/prop-types */
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import FileUploader from '@/components/FileUploader';
import { HUB_TYPES } from '@/constants/adsTypes';

import AccessSettings from './AccessSettings';
import HubNameEditor from './HubNameEditor';

export default function HubCard({ hub }) {
  return (
    <Paper
      elevation={3}
      sx={{
        position: 'relative',
        border: '1px solid #CAC7C7',
        borderRadius: '0.5rem',
        padding: '1.25rem 2rem 2rem',
        boxShadow: '0 0 8px rgb(0 0 0 / 0.1)',
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" marginLeft={-2}>
        <HubNameEditor hub={hub} />
        <AccessSettings hub={hub} />
      </Box>
      <Box display="flex" flexWrap="wrap" gap={1} marginTop={2}>
        <FileUploader
          hubId={hub.id}
          hubType={HUB_TYPES.Google}
          prevFile={hub?.dashboards?.[0]?.fileName}
        />
        <FileUploader
          hubId={hub.id}
          hubType={HUB_TYPES.Meta}
          prevFile={hub?.dashboards?.[1]?.fileName}
        />
        <FileUploader
          hubId={hub.id}
          hubType={HUB_TYPES.LINE}
          prevFile={hub?.dashboards?.[2]?.fileName}
        />
        <Button
          endIcon={<AddCircleIcon />}
          href="https://www.surveycake.com/s/Q2z6K"
          target="_blank"
          variant="contained"
        >
          More
        </Button>
      </Box>
    </Paper>
  );
}
