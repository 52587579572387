import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const VITE_ROOT_API_URL = import.meta.env.VITE_ROOT_API_URL;

export const appApi = createApi({
  reducerPath: 'api',
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: VITE_ROOT_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.app?.token;
      if (token) {
        headers.set('x-auth-token', token);
      }
      return headers;
    }
  }),

  endpoints: () => ({})
});