import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useCurrentToken, useVerifySavedToken } from '@/hooks/auth';
import queryString from 'query-string';
import MainLayout from './layout/MainLayout';
import MinimalLayout from './layout/MinimalLayout';
import Home from './pages/Home';
import TabDashboard from './pages/TabDashboard';
import Hub from './pages/Hub';
import DashboardCookieless from './pages/DashboardCookieless';
import Portal from './pages/portal';
import SignUp from './pages/portal/SignUp';
import Upload from './pages/report/Upload';
import ShareLink from './pages/share/ShareLink';
import ShareLayout from './layout/ShareLayout';

const PublicRoute = ({ children }) => {
  const token = useCurrentToken();
  const url = new URL(window.location.href);
  const redirect = queryString.parse(url.search).redirect;
  return token ? <Navigate to={redirect ? redirect : '/'} /> : children;
};

PublicRoute.propTypes = {
  children: PropTypes.node
};

const ProtectedRoute = ({ children }) => {
  useVerifySavedToken();
  const token = useCurrentToken();
  const url = new URL(window.location.href);
  const redirect = encodeURIComponent(url.href.replace(url.origin, ''));
  return token ? children : <Navigate to={`/login?redirect=${redirect}`} />;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node
};

const Routes = () => {
  return (
    <ReactRoutes>
      <Route element={<PublicRoute><MinimalLayout /></PublicRoute>}>
        <Route path="/login" element={<Portal />} />
        <Route path="/verify" element={<Portal />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/sendVerificationMail" element={<Portal />} />
        <Route path="/contactUs" element={<Portal />} />
      </Route>
      <Route element={<PublicRoute><ShareLayout /></PublicRoute>}>
        <Route path="/share/:hubId" element={<ShareLink />} />
        <Route path="/share-hub/dashboard/:hubId" element={<DashboardCookieless />} />
      </Route>
      <Route path="/" element={<ProtectedRoute><MainLayout /></ProtectedRoute>}>
        <Route index element={<Home />} />
        <Route path="/dashboard" element={<DashboardCookieless />} />
        <Route path="/hub" element={<Hub />} />
        <Route path="/tabDashboard" element={<TabDashboard />} />
        <Route path="/FBUploadReport" element={<Upload/>}/>
        {/* <Route path="profile/:id" element={<Temp />} /> */}
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </ReactRoutes>
  );
}

export default Routes;
