/* eslint-disable react/prop-types */
import { toast } from 'react-toastify';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import googleLogo from '@/assets/logos/google.svg';
import lineLogo from '@/assets/logos/line.svg';
import metaLogo from '@/assets/logos/meta.svg';
import { VisuallyHiddenInput } from '@/components/VisuallyHiddenInput';
import { HUB_TYPES, REPORT_TYPES } from '@/constants/adsTypes';
import useEventTracking from '@/hooks/useEventTracking';

function getLogo(hubType) {
  switch (hubType) {
    case HUB_TYPES.GOOGLE:
      return { src: googleLogo, alt: 'Google logo' };
    case HUB_TYPES.LINE:
      return { src: lineLogo, alt: 'LINE logo' };
    case HUB_TYPES.Meta:
      return { src: metaLogo, alt: 'Meta logo' };
    default:
      return { src: googleLogo, alt: 'Google logo' };
  }
}

function Logo({ hubType }) {
  const { alt, src } = getLogo(hubType);
  return <img src={src} alt={alt} style={{ margin: 'auto' }} />;
}

export default function UploadOptions({ handleFileChange, hubType, prevFile }) {
  const trackEvent = useEventTracking();

  function handleUploadButtonClick() {
    trackEvent('file_upload', {
      report_type: REPORT_TYPES[hubType],
    });
  }

  return (
    <>
      <Box
        display="flex"
        flexBasis="50%"
        flexDirection="column"
        alignItems="center"
        gap={4}
      >
        <Typography color="warning" component="h3" variant="h5">
          選擇檔案上傳
        </Typography>
        <ul style={{ margin: 0 }}>
          <li>目前僅提供 .csv 格式檔案</li>
          <li>
            格式說明請閱讀
            <Link
              href="https://www.notion.so/tenmax/1279b8206fa9807ea3c2c1609d32878f"
              rel="noopener noreferrer"
              target="_blank"
              underline="hover"
            >
              「說明文件」
            </Link>
          </li>
        </ul>
        {prevFile && (
          <Typography
            fontSize={12}
            fontWeight={500}
            marginBottom={-3.5}
            textAlign="center"
            sx={{ textWrap: 'balance' }}
          >
            最後上傳檔案：{prevFile}
          </Typography>
        )}
        <Button
          component="label"
          onClick={handleUploadButtonClick}
          role={undefined}
          tabIndex={-1}
          variant="contained"
        >
          選擇檔案
          <VisuallyHiddenInput
            accept=".csv"
            type="file"
            onChange={handleFileChange}
          />
        </Button>
      </Box>
      <Box
        display="flex"
        flexBasis="50%"
        flexDirection="column"
        alignItems="center"
        gap={4}
      >
        <Typography component="h3" variant="h5">
          選擇串接 API
        </Typography>
        <Logo hubType={hubType} />
        <Button
          variant="outlined"
          onClick={() => toast.info('Premium 功能 - 尚未開放')}
        >
          選擇串接
        </Button>
      </Box>
    </>
  );
}
