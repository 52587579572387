/* eslint-disable react/prop-types */
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import WarningIcon from '@mui/icons-material/Warning';
import Typography from '@mui/material/Typography';

import { RESPONSE_STATUS } from './constants';

function getStatusTitle(status) {
  switch (status) {
    case RESPONSE_STATUS.FAILED:
      return {
        color: '#ED6C02',
        icon: WarningIcon,
        text: '檔案缺少欄位',
      };
    case RESPONSE_STATUS.SUCCESS:
      return {
        color: '#2E7D32',
        icon: CheckCircleIcon,
        text: '檔案上傳完成',
      };
    default:
      return {
        color: '#1E8AD2',
        icon: CloudUploadIcon,
        text: '檔案上傳中',
      };
  }
}

export default function StatusTitle({ status }) {
  const { color, icon: Icon, text } = getStatusTitle(status);

  return (
    <Typography
      align="center"
      alignItems="center"
      component="h3"
      display="flex"
      gap={1}
      justifyContent="center"
      variant="h5"
      sx={{ color }}
    >
      <Icon />
      {text}
    </Typography>
  );
}
