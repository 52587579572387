import { getRequestOptions } from '@/helpers/request';
import { getI18nMessageKey } from '@/helpers/message';
import { toast } from 'react-toastify';
import i18n from '@/i18n';

export const createCommonExtraReducers = (builder) => {
  builder
    .addMatcher(
      (action) => /execute(Query|Mutation)\/pending$/.test(action.type),
      (state, action) => {
        const requestOptions = action.meta.arg.originalArgs?.options;
        const { triggerLoading, loadingCount, loadingText } = getRequestOptions(requestOptions);
        triggerLoading && (state.loading += loadingCount);
        loadingText && (state.loadingText = requestOptions.loadingText);
      }
    )
    .addMatcher(
      (action) => /execute(Query|Mutation)\/fulfilled$/.test(action.type),
      (state, action) => {
        const requestOptions = action.meta.arg.originalArgs?.options;
        const endpoint = action.meta.arg.endpointName;
        const { triggerLoading, triggerSuccessNoti, loadingText } = getRequestOptions(requestOptions);
        triggerLoading && (state.loading -= 1);
        loadingText && (state.loadingText = '');

        if (triggerSuccessNoti) {
          const msg = getI18nMessageKey(endpoint, 'success');
          if (msg) {
            toast.success(i18n.t(`message:${msg}`));
          }
        }
      }
    )
    .addMatcher(
      (action) => /execute(Query|Mutation)\/rejected$/.test(action.type),
      (state, action) => {
        const requestOptions = action.meta.arg.originalArgs?.options;
        const endpoint = action.meta.arg.endpointName;
        const status = action.payload?.data?.status;
        const { triggerLoading, triggerErrorNoti, triggerErrorNotiExclude, loadingText } = getRequestOptions(requestOptions);
        triggerLoading && state.loading > 0 && (state.loading -= 1);
        loadingText && (state.loadingText = '');

        if (status === 403000) {
          // dispatch logout by middleware
          return;
        }

        if (triggerErrorNoti && !triggerErrorNotiExclude?.includes(status)) {
          const msg = getI18nMessageKey(endpoint, 'error', status);
          toast.error(i18n.t(`message:${msg}`));
        }
      }
    );
};
