import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { LookerEmbedSDK } from '@looker/embed-sdk';
import queryString from 'query-string';
import CircularProgress from '@mui/material/CircularProgress';

const VITE_ROOT_API_URL = import.meta.env.VITE_ROOT_API_URL;


const DashboardCookieless = () => {
  const embedContainer = useRef(null);
  const initialized = useRef(false);
  const location = useLocation();
  const params = queryString.parse(location.search);
  const { url, dashboardId, hubId } = params;
  const embedDashboardId = url ? url.split('/').pop() : null;
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    if (!initialized.current && embedDashboardId) {
      initialized.current = true;
      if (embedContainer.current) {
        const iframes = embedContainer.current.getElementsByTagName('iframe');
        while (iframes.length > 0) {
          iframes[0].remove();
        }
      }
      setIsLoading(true);

      LookerEmbedSDK.initCookieless(
        'retailmax.cloud.looker.com',
        VITE_ROOT_API_URL+`/acquire-cookie-less-session?dashboardId=${dashboardId}&hubId=${hubId}`,
        VITE_ROOT_API_URL+'/generate-cookie-less-token'
      );

      LookerEmbedSDK.createDashboardWithId(embedDashboardId)
        .appendTo(embedContainer.current)
        .build()
        .connect()
        .then((dashboard) => {
          console.log('Dashboard created');
          setIsLoading(false);
          initialized.current = false;
        })
        .catch((error) => {
          console.error('Error creating dashboard', error);
        });
    }
  }, [embedDashboardId]);

  

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid container item alignSelf="stretch">
        <div id="dashboard-container" ref={embedContainer} style={{ width: '100%', height: 'auto' }} />
        {
          isLoading && <CircularProgress sx={{ margin: 'auto' }}/>
        }
      </Grid>
    </Grid>
  );
};

export default DashboardCookieless;
