import { useSelector } from 'react-redux';

export default function useEventTracking() {
  const companyId = useSelector(state => state.app.user?.roles[0].company.companyId);
  const hubId = useSelector(state => state.api.queries?.['getHub(undefined)']?.data?.[0].id);

  if (typeof window.gtag === 'undefined') {
    console.error('gtag is not defined');
    return () => {};
  }

  return (eventName, eventParams) =>
    window.gtag('event', eventName, {
      company_id: companyId,
      hub_id: hubId,
      ...eventParams,
    });
}
