import { createSlice } from '@reduxjs/toolkit';
import { appApi } from './api';
import { createCommonExtraReducers } from './extraReducers';


const initialState = {
  
};

export const hubSlice = createSlice({
  name: 'hub',
  initialState,
  reducers: {
    getShareHub(state, action) {
      state.shareHub = action.payload;
    },
    setDashboardToken(state, action) {
      state.dashboardToken = action.payload;
    }
  },
  extraReducers: (builder) => {
    createCommonExtraReducers(builder);
  },
});

const extendedApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getShareHub: builder.query({
      query: ({ hubId }) => ({
        url: `share/hub/${hubId}`,
        method: 'GET'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getShareHub(data));
        } catch (err) {
          console.log(err);
        }
      },
    }),
    getCookielessSession: builder.query({
      query: () => 'acquire-cookie-less-session'
    }),
    getShareNavigation: builder.query({
      query: (hubId) => `share/hub-navigation/${hubId}`
    }),
    getNavigation: builder.query({
      query: () => 'navigation-v2'
    }),
    getHub: builder.query({
      query: () => 'hub'
    }),
    updateHub: builder.mutation({
      query: ( hub ) => {
        return {
          url: 'hub',
          method: 'PUT',
          body: hub
        };
      },
    }),
  })
})

export const {
  getShareHub,
  setDashboardToken
} = hubSlice.actions;
export const {
  useGetShareHubQuery,
  useGetShareNavigationQuery,
  useGetCookielessSessionQuery,
} = extendedApi;

export default hubSlice.reducer;