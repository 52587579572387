import { configureStore } from '@reduxjs/toolkit';
import { appApi } from 'slice/api';
import appReducer from 'slice/app';
import hubReducer from 'slice/hub';
import autoDispatchMiddleware from './middleware/autoDispatchMiddleware';

const store = configureStore({
  reducer: {
    [appApi.reducerPath]: appApi.reducer,
    app: appReducer,
    hub: hubReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(appApi.middleware)
      .concat(autoDispatchMiddleware),
});

export default store;
