const MESSAGES = {
  GENERAL: {
    error: 'error.general'
  },
  register: {
    'success': 'success.register', // example
    'error': 'error.register.general', // example
  },
  login: {
    '400010': 'error.login.wrongAccountOrPassword',
    '403028': 'error.login.notVerifyEmail',
    '404010': 'error.login.wrongAccountOrPassword'
  },
};

export default MESSAGES;