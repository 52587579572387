import React, { useState } from 'react';
import { Container, Grid, TextField, Button } from '@mui/material';
import { useGetShareHubQuery, setDashboardToken } from '@/store/slices/hub';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const ShareLink = () => {
  const dispatch = useDispatch();
  const [token, setToken] = useState('');
  const hubName = useSelector(state => state?.hub?.shareHub?.name);
  const shareStatus = useSelector(state => state?.hub?.shareHub?.shareStatus);

  const location = useLocation();
  const hubId = location.pathname.split('/').filter(Boolean).pop();
  if (!hubId) {
    return null;
  }
  

  const { refetch: refetchShareHub, shareHubData: data } = useGetShareHubQuery({ hubId });

  const handleTokenSubmit = () => {
    console.log(token);
    dispatch(setDashboardToken({ 'hubId': hubId, token: token }));
  };
  
  return (
    <Container style={{ marginTop: '30px' }}>
      {
        <Grid>Welcome {hubName}</Grid>
      } 
      {
        shareStatus === 'PUBLIC_LINK_TOKEN' && <Grid>
          <div>
            <TextField value={token} 
              onChange={(e) => setToken(e.target.value)}
              id="token-basic" 
              label="請輸入 Hub 密碼" 
              variant="outlined" sx={{ mt:2 }}/>
          </div>
          <div>
            <Button variant="outlined" sx={{ mt:2 }} onClick={handleTokenSubmit}>送出</Button>
          </div>
        </Grid>
      }
    </Container>
  );
};

export default ShareLink;