export const getRequestOptions = (options) => {
  const defaultOptions = {
    triggerSuccessNoti: true,
    triggerErrorNoti: true,
    triggerErrorNotiExclude: [], // status code ex. 403010
    triggerLoading: true,
    loadingCount: 1, // this is evaluated only when triggerLoading is true.
    loadingText: ''
  };
  const opts = options ? Object.assign({}, defaultOptions, options) : defaultOptions;
  return opts;
};