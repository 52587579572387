/* eslint-disable react/prop-types */
import { useState } from 'react';
import { toast } from 'react-toastify';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import {
  useGetHubQuery,
  useGetNavigationQuery,
  useUpdateHubMutation,
} from '@/store/slices/app';

const MAXIMUM_INPUT_LENGTH = 20;

export default function HubNameEditor({ hub }) {
  const { id, name } = hub;

  const { refetch: refetchHub } = useGetHubQuery();
  const { refetch: refetchNavigation } = useGetNavigationQuery();
  const [updateHub, { isLoading }] = useUpdateHubMutation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [inputValue, setInputValue] = useState(name || '');

  const isInputLengthExceeded = inputValue.length > MAXIMUM_INPUT_LENGTH;

  function handleClose() {
    setIsDialogOpen(false);
    !inputValue && setInputValue(name);
  }

  const handleInputChange = e => setInputValue(e.target.value);

  async function handleSubmit(e) {
    e.preventDefault();
    if (inputValue === name || isInputLengthExceeded) return;
    await updateHub({ id, name: inputValue });
    toast.success('Hub 更新成功');
    await refetchNavigation();
    await refetchHub();
    setIsDialogOpen(false);
  }

  return (
    <>
      <Button
        color="inherit"
        onClick={() => setIsDialogOpen(true)}
        size="large"
        sx={{
          fontSize: '1.5rem',
          lineHeight: 1.5,
          overflowWrap: 'anywhere',
          textAlign: 'left',
        }}
      >
        {name}
      </Button>
      <Dialog fullWidth onClose={handleClose} open={isDialogOpen}>
        <DialogTitle
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          lineHeight={1.5}
          sx={{ padding: '0.5rem 1rem 0.5rem 1.5rem' }}
        >
          編輯 Hub 名稱
          <IconButton color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ pb: 3 }}>
          <Box
            component="form"
            display="grid"
            gap={1}
            justifyItems="center"
            marginInline="auto"
            maxWidth="22rem"
            onSubmit={handleSubmit}
          >
            <TextField
              error={isInputLengthExceeded}
              fullWidth
              helperText={`${inputValue.length} / ${MAXIMUM_INPUT_LENGTH}`}
              inputProps={{ maxLength: MAXIMUM_INPUT_LENGTH, minLength: 1 }}
              label="Hub 名稱"
              onChange={handleInputChange}
              value={inputValue}
              variant="outlined"
            />
            <Button
              disabled={!inputValue.length || isInputLengthExceeded || isLoading}
              size="large"
              type="submit"
              variant="contained"
            >
              儲存
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
