import Grid from '@mui/material/Grid';

const Upload = () => {

  return (
    <Grid container>
      <Grid item sx={{ margin: 'auto' }}>
        <div>
          Upload your report here
        </div>
        <input type="file" />
      </Grid>
    </Grid>
  )
}

export default Upload;