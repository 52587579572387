import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';

import useEventTracking from '@/hooks/useEventTracking';
import { useGetHubQuery } from '@/store/slices/app';
import HubCard from './Hub/HubCard';

export default function Home() {
  const trackEvent = useEventTracking();
  const { data: hubs } = useGetHubQuery();

  if (!hubs || !hubs.length) return null;

  function handleHubCreationClick() {
    trackEvent('hub_creation_click');
    toast.info('Premium 功能 — 尚未開放');
  }

  return (
    <Box padding={4} width="100%">
      {hubs.map(hub => (
        <HubCard hub={hub} key={hub.id} />
      ))}
      <div
        onClick={handleHubCreationClick}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.75rem',
          marginBlock: '2rem',
          border: '1px solid rgba(202, 199, 199)',
          borderRadius: '0.5rem',
          padding: '1.25rem 2rem',
          opacity: '0.8',
          cursor: 'pointer',
        }}
      >
        <span
          style={{
            border: '1px dotted rgba(165, 165, 165)',
            borderRadius: '0.25rem',
            height: 26,
          }}
        >
          <AddIcon />
        </span>
        <span
          style={{
            fontSize: 20,
            lineHeight: 1,
          }}
        >
          建立 Hub
        </span>
      </div>
    </Box>
  );
}
