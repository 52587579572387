import Box from '@mui/material/Box';
import { useGetHubQuery } from '@/store/slices/app';
import HubCard from './HubCard';

export default function Hub() {
  const { data } = useGetHubQuery();

  if (!data || !data.length) return null;

  return (
    <Box padding={4} width="100%">
      <HubCard hub={data[0]} />
    </Box>
  );
}
