const autoDispatchMiddleware = storeAPI => next => action => {
  let result = next(action);

  if(/execute(Query|Mutation)\/rejected$/.test(action.type)) {
    const status = action.payload?.data?.status;
    if (status === 403000) {
      storeAPI.dispatch({ type: 'app/logout' });
      storeAPI.dispatch({ type: 'api/resetApiState' }); // appApi.util.resetApiState()
    }
  }

  return result;
};

export default autoDispatchMiddleware;