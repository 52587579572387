/* eslint-disable react/prop-types */
import { useState } from 'react';
import { toast } from 'react-toastify';

import CloseIcon from '@mui/icons-material/Close';
import LinkIcon from '@mui/icons-material/Link';
import ShareIcon from '@mui/icons-material/Share';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { SHARE_STATUS } from '@/constants/access';
import useEventTracking from '@/hooks/useEventTracking';
import { useUpdateHubMutation } from '@/store/slices/app';

const VITE_ROOT_URL = import.meta.env.VITE_ROOT_URL;

export default function AccessSettings({ hub }) {
  const { id, name, shareStatus } = hub;

  const trackEvent = useEventTracking();
  const [updateHub] = useUpdateHubMutation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [access, setAccess] = useState(shareStatus || SHARE_STATUS.INTERNAL);

  const handleClose = () => setIsDialogOpen(false);

  function handleShareIconClick() {
    setIsDialogOpen(true);
    trackEvent('share_icon_click');
  }

  async function handleShareStatusChange(e) {
    const { value } = e.target;

    if (
      value === SHARE_STATUS.PUBLIC_LINK_TOKEN ||
      value === SHARE_STATUS.SPECIFIC_USER
    ) {
      return toast.info('Premium 功能 - 尚未開放');
    }

    setAccess(value);
    await updateHub({ id, name, shareStatus: value });
    toast.success('Hub 更新成功');
  }

  async function handleShareLinkCopy() {
    const link = `${VITE_ROOT_URL}/share/${id}`;

    trackEvent('share_link_copy', {
      link,
      share_status: access,
    });

    if (
      access !== SHARE_STATUS.PUBLIC_LINK &&
      access !== SHARE_STATUS.PUBLIC_LINK_TOKEN
    ) {
      return toast.warning('請先設定分享權限');
    }

    try {
      await navigator.clipboard.writeText(link);
      toast.success('已複製連結');
    } catch (error) {
      console.error(error.message);
    }
  }

  return (
    <>
      <IconButton
        color="primary"
        onClick={handleShareIconClick}
        sx={{ boxShadow: '0 0 8px rgb(0 0 0 / 0.2)' }}
      >
        <ShareIcon />
      </IconButton>
      <Dialog fullWidth onClose={handleClose} open={isDialogOpen}>
        <DialogTitle
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          lineHeight={1.5}
          sx={{ padding: '0.5rem 1rem 0.5rem 1.5rem' }}
        >
          分享與權限設定
          <IconButton color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{ display: 'grid', justifyItems: 'center', gap: '1.5rem' }}
        >
          <FormControl>
            <RadioGroup value={access} onChange={handleShareStatusChange}>
              <FormControlLabel
                control={<Radio />}
                label="關閉此份報表分享功能"
                value={SHARE_STATUS.INTERNAL}
              />
              <FormControlLabel
                control={<Radio />}
                label="擁有連結的任何人皆可瀏覽"
                value={SHARE_STATUS.PUBLIC_LINK}
              />
              <FormControlLabel
                control={<Radio color="default" />}
                label="使用密碼瀏覽"
                value={SHARE_STATUS.PUBLIC_LINK_TOKEN}
                sx={{
                  color: 'rgb(0 0 0 / 0.5)',
                  '.MuiRadio-root': { color: 'currentcolor' },
                }}
              />
              <FormControlLabel
                control={<Radio color="default" />}
                label="特定人員瀏覽"
                value={SHARE_STATUS.SPECIFIC_USER}
                sx={{
                  color: 'rgb(0 0 0 / 0.5)',
                  '.MuiRadio-root': { color: 'currentcolor' },
                }}
              />
            </RadioGroup>
          </FormControl>
          <Button
            onClick={handleShareLinkCopy}
            startIcon={<LinkIcon />}
            variant="contained"
          >
            複製連結
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
