import MESSAGES from '@/constants/messages';

export const getI18nMessageKey = (endpoint, type, statusCode) => {
  switch (type) {
  case 'success':
    return MESSAGES[endpoint]?.success;
  case 'error':
    if (MESSAGES[endpoint]?.[statusCode]) {
      return MESSAGES[endpoint][statusCode];
    } else if (MESSAGES[endpoint]?.error) {
      return MESSAGES[endpoint].error;
    }
    return MESSAGES.GENERAL.error;
  default:
    return null;
  }
};

export default MESSAGES;