import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import VisualImg from '@/assets/portal/portal-visual.png';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useRegisterMutation } from 'slice/app';
import { useTranslation } from 'react-i18next';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';


const SignUp = () => {
  const { t } = useTranslation('portal');
  const [register, { isLoading }] = useRegisterMutation();
  const navigate = useNavigate();


  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      contactName: '',
      companyName: '',
      areaCode: '',
      phoneNumber: '',
      agreeTOS: false,
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email(t('validation.email.invalid'))
        .required(t('validation.email.required')),
      password: yup
        .string()
        .min(8, t('validation.password.minLength'))
        .required(t('validation.required', { field: t('field.password') })),
      confirmPassword: yup
        .string()
        .required(t('validation.required', { field: t('field.confirmPassword') }))
        .test('passwords-match', t('validation.password.match'), function(value) {
          return this.parent.password === value;
        },),
      areaCode: yup.string().required(t('validation.required', { field: t('field.areaCode') })),
      phoneNumber: yup.string().required(t('validation.required', { field: t('field.phoneNumber') })),
      contactName: yup
        .string()
        .required(t('validation.required', { field: t('field.contactName') })),
      companyName: yup
        .string()
        .required(t('validation.required', { field: t('field.companyName') })),
      agreeTOS: yup.boolean().oneOf([true], t('validation.required', { field: t('field.agreeTOS') })),
        
    }),
    onSubmit: ({ email, password, contactName, companyName, areaCode, phoneNumber }) => {
      register({ email, password, contactName, companyName, areaCode, phoneNumber }).then((res) => {
        if (!res.error) {
          navigate('/login');
        }
      });
    }
  });

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid item xs={false} sm={4} md={6}
        sx={{
          backgroundImage: `url(${VisualImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={6} component={Paper} sx={{ display: 'flex' }}>
        <Box sx={{ maxWidth: '430px', mx: [4, 'auto'], my: 'auto' }}>
          <Typography component="h1" variant="h4" gutterBottom sx={{ alignSelf: 'flex-start' }}>
            {t('login.signup')}
          </Typography>
          <Typography paragraph>
            {t('login.welcome')}<br />{t('login.callToAction')}
          </Typography>
          <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
            <TextField
              fullWidth
              margin="normal"
              required
              id="email"
              name="email"
              label={t('field.email')}
              autoComplete="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              fullWidth
              margin="normal"
              required
              id="password"
              name="password"
              label={t('field.password')}
              type="password"
              autoComplete="current-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <TextField
              fullWidth
              margin="normal"
              required
              id="confirmPassword"
              name="confirmPassword"
              label={t('field.confirmPassword')}
              type="password"
              autoComplete="current-password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
            />
            <OutlinedInput
              fullWidth
              required
              label=""
              id="areaCode"
              placeholder={t('field.areaCode')}
              value={formik.values.areaCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.areaCode && Boolean(formik.errors.areaCode)}
              helperText={formik.touched.areaCode && formik.errors.areaCode}
              startAdornment={<InputAdornment position="start">+</InputAdornment>}
              sx={{ mt: 1 }}
            />
            <TextField
              fullWidth
              required
              margin="normal"
              id="phoneNumber"
              name="phoneNumber"
              label={t('field.phoneNumber')}
              autoComplete="phoneNumber"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
              helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            />
            
            <TextField
              fullWidth
              margin="normal"
              required
              id="contactName"
              name="contactName"
              label={t('field.contactName')}
              autoComplete="contactName"
              value={formik.values.contactName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.contactName && Boolean(formik.errors.contactName)}
              helperText={formik.touched.contactName && formik.errors.contactName}
            />
            <TextField
              fullWidth
              margin="normal"
              required
              id="companyName"
              name="companyName"
              label={t('field.companyName')}
              autoComplete="companyName"
              value={formik.values.companyName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.companyName && Boolean(formik.errors.companyName)}
              helperText={formik.touched.companyName && formik.errors.companyName}
            />
            <FormControl 
              error={formik.touched.agreeTOS && Boolean(formik.errors.agreeTOS)}
              sx={{ mt: 1 }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name="agreeTOS"
                    checked={formik.values.agreeTOS}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                }
                label={
                  <span>
                    {t('field.agreeTOS')} &nbsp;
                    <a href="https://storage.googleapis.com/onemax-storage/tos/20241026-v1.html" 
                      target="_blank" rel="noopener noreferrer">
                      使用者條款
                    </a>
                  </span>
                }
              />
              {formik.touched.agreeTOS && formik.errors.agreeTOS && (
                <FormHelperText>{formik.errors.agreeTOS}</FormHelperText>
              )}
            </FormControl>
            <Button type="submit" variant="contained" fullWidth size="large" sx={{ mt: 3, mb: 2 }}
              disabled={isLoading}>
              {t('button.signup')}
            </Button>
            <Grid container>
              <Grid item xs>
                <Typography variant="body2" color="text.secondary" align="center">
                  <Link href="/login" color="inherit">
                    {t('button.login')}
                  </Link> &nbsp;|&nbsp;
                  <Link href={t('privacyPolicy.link')} color="inherit">
                  {t('privacyPolicy.text')}
                  </Link>
              </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default SignUp;