import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import TutorialVideo, { YOUTUBE_VIDEO_ID } from '@/components/TutorialVideo';
import { useGetNavigationQuery } from '@/store/slices/app';
import useEventTracking from '@/hooks/useEventTracking';

const drawerWidth = 240;

const getItemPath = (item , category) => {
  switch (item.itemType) {
    case 'ROUTE':
      return item.url;
    case 'DASHBOARD':
      return `/dashboard?url=${item.embedUrl}&dashboardId=${item.dashboardId}&hubId=${category?.hubId}`;
    case 'TAB': {
      const encodedData = encodeURIComponent(JSON.stringify(item.content));
      return `/tabDashboard?data=${encodedData}`;
    }
    default:
      return '/';
  }
};


const Sidebar = (props) => {
  const trackEvent = useEventTracking();
  const { data: navData } = useGetNavigationQuery();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { handleDrawerToggle, handleDrawerTransitionEnd, handleDrawerClose, mobileOpen } = props;
  const location = useLocation();
  const currentPath = location.pathname + location.search;
  
  if (!navData || !navData.length) return (
    <Box
      component="nav"
      sx={{
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
        position: 'relative',
      }}
    />
  );

  const drawer = (
    <Box display="flex" flexDirection="column" height="100%" onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <List sx={{ paddingBlock: 0 }}>
        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname === '/'}
            component={Link}
            to='/'
          >
            <ListItemText primary="首頁" />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname === '/hub'}
            component={Link}
            to='/hub'
            sx={{ overflowWrap: 'anywhere' }}
          >
            <ListItemText primary={navData[1].category.label} />
          </ListItemButton>
        </ListItem>
        <Divider />
        {navData[1].items.map(item => (
          <Fragment key={item.dashboardId}>
            <ListItem key={item.dashboardId} disablePadding>
              <ListItemButton
                selected={currentPath === getItemPath(item, navData[1].category)}
                component={Link}
                to={getItemPath(item, navData[1].category)}
                onClick={() => trackEvent('tab_click', { tab: item.label })}
              >
                <ListItemText inset primary={item.label} />
              </ListItemButton>
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </List>
      <Box display="flex" flexDirection="column" gap={2} marginTop="auto" padding={2}>
        {matches ? (
          <TutorialVideo buttonProps={{ variant: 'outlined' }} />
        ) : (
          <Button
            href={`https://www.youtube.com/watch?v=${YOUTUBE_VIDEO_ID}`}
            rel="noopener noreferrer"
            target="_blank"
            variant="outlined"
          >
            教學影片
          </Button>
        )}
        <Button
          href="https://www.notion.so/tenmax/1279b8206fa9807ea3c2c1609d32878f"
          rel="noopener noreferrer"
          target="_blank"
          variant="outlined"
        >
          說明文件
        </Button>
      </Box>
    </Box>
  );

  return (
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          position: 'relative',
        }}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              pt: 7
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          open
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              position: 'absolute',
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
  );
}

Sidebar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  handleDrawerTransitionEnd: PropTypes.func,
  handleDrawerClose: PropTypes.func,
  mobileOpen: PropTypes.bool
};

export default Sidebar;
