/* eslint-disable react/prop-types */
import { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { SHARE_STATUS } from '@/constants/access';
import useEventTracking from '@/hooks/useEventTracking';
import { useGetShareNavigationQuery } from '@/store/slices/hub';

const drawerWidth = 240;

const getItemPath = (item, hubId) => {
  switch (item.itemType) {
    case 'ROUTE':
      return item.url;
    case 'DASHBOARD':
      return `/share-hub/dashboard/${hubId}?url=${item.embedUrl}&dashboardId=${item.dashboardId}&hubId=${hubId}`;
    default:
      return '/';
  }
};

export default function Sidebar({
  handleDrawerClose,
  handleDrawerToggle,
  handleDrawerTransitionEnd,
  mobileOpen,
  shareStatus,
}) {
  const trackEvent = useEventTracking();
  const { pathname, search } = useLocation();

  const hubId = pathname.split('/').at(-1);
  const { data } = useGetShareNavigationQuery(hubId);

  if (
    !hubId ||
    !data ||
    !data.length ||
    (shareStatus !== SHARE_STATUS.PUBLIC_LINK &&
      shareStatus !== SHARE_STATUS.PUBLIC_LINK_TOKEN)
  ) {
    <Box
      component="nav"
      sx={{
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
        position: 'relative',
      }}
    />;
  }

  const navData = data?.[0];

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <List sx={{ paddingBlock: 0 }}>
        <ListItem disablePadding>
          <ListItemButton
            selected={pathname === '/share'}
            component={Link}
            to={`/share/${hubId}`}
            sx={{ overflowWrap: 'anywhere' }}
          >
            <ListItemText primary={navData?.category.label} />
          </ListItemButton>
        </ListItem>
        <Divider />
        {navData?.items.map(item => {
          const link = getItemPath(item, hubId);

          return (
            <Fragment key={item.dashboardId}>
              <ListItem key={item.dashboardId} disablePadding>
                <ListItemButton
                  selected={pathname + search === link}
                  component={Link}
                  to={link}
                  onClick={() => trackEvent('tab_click', { tab: item.label })}
                >
                  <ListItemText inset primary={item.label} />
                </ListItemButton>
              </ListItem>
              <Divider />
            </Fragment>
          );
        })}
      </List>
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
        position: 'relative',
      }}
    >
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            pt: 7,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        open
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            position: 'absolute',
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
