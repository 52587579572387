import Routes from '@/Routes';
import ScrollToTop from '@/components/utils/ScrollToTop';
import store from './store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux'
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { theme } from './theme';

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ScrollToTop>
        <Routes />
      </ScrollToTop>
      <ToastContainer position="top-center" />
    </ThemeProvider>
  </Provider>
);

export default App
