import { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import queryString from 'query-string';
import PropTypes from 'prop-types';

const CustomTabPanel = (props) => {
  const { children, currentTab, index, isLoading, ...other } = props;

  return (
    <div
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: '100%', display: currentTab === index ? 'flex' : 'none' }}
      {...other}
    >
      {isLoading && <CircularProgress sx={{ margin: 'auto' }}/>}
      {children}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  currentTab: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const TabDashboard = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const location = useLocation();
  const params = queryString.parse(location.search);
  const { data } = params;
  const parseData = useMemo(() => data ? JSON.parse(data) : [], [data]);
  const [isActivated, setIsActivated] = useState(parseData.map(() => false));
  const [isLoading, setIsLoading] = useState(parseData.map(() => true));

  const handleLoad = (index) => {
    setIsLoading((prevLoading) => {
      const newLoading = [...prevLoading];
      newLoading[index] = false;
      return newLoading;
    });
  };

  const changeTab = (event, i) => {
    setCurrentTab(i);
  };

  useEffect(() => {
    if (parseData.length > 0) {
      setIsActivated((prevActivated) => {
        const newActivated = [...prevActivated];
        newActivated[currentTab] = true;

        return newActivated;
      });
    }
  }, [parseData, currentTab])

  return (
    <Grid container sx={{ width: '100%' }} flexDirection="column">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentTab} onChange={changeTab} aria-label="basic tabs example">
          {parseData.map((tab, index) => (
            <Tab key={index} label={tab.label} />
          ))}
        </Tabs>
      </Box>
      <Box flexGrow={1}>
        {parseData.map((tab, index) => (
          <CustomTabPanel key={index} currentTab={currentTab} index={index} isLoading={isLoading[index]}>
            {isActivated[index] && <iframe
              src={tab.url} onLoad={() => handleLoad(index)} width="100%" height="100%"
              style={{border: 'none', display: isLoading[index] ? 'none' : 'block'}}
            />}
          </CustomTabPanel>
        ))}
      </Box>
    </Grid>
  )
}

export default TabDashboard;