import { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { logout } from 'slice/app'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircle from '@mui/icons-material/AccountCircle'

import LogoImg from '@/assets/portal/tenmax-design.png';

const Header = (props) => {
  const { handleDrawerToggle } = props;
  const dispatch = useDispatch();
  const { email } = props;

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const open = Boolean(menuAnchorEl);

  const openMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
  }

  const handleLogin = () => {
    location.href = '/login';
  }
  return (
    <AppBar 
      component="header"
      position="sticky"
      sx={{
        backgroundImage: 'linear-gradient(90deg, #1cadc3, #1e8ad2);',
        zIndex: 1201,
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="div"
          sx={{ display: { xs: 'none', sm: 'flex' } }}
        >
          <img src={LogoImg} alt="logo" style={{ width: '150px' }} /> 
          <div style={{ marginLeft: '10px', height: '20px', paddingTop: '6px', fontWeight: 600 }}> Dashboard Hub </div>
        </Typography>
        <div>
          {email}
          <IconButton size="large" onClick={openMenu} color="inherit">
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={menuAnchorEl}
            keepMounted
            open={open}
            onClose={closeMenu}
          > { 
              email && <MenuItem onClick={handleLogout}>Logout</MenuItem>
            }
            { 
              !email && <MenuItem onClick={handleLogin}>Login</MenuItem>
            }
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  handleDrawerToggle: PropTypes.func
};

export default Header
