import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(0 0 0 / 0.4)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '4rem',
          lineHeight: 1,
          boxShadow: 'none',
          textTransform: 'none',
        },
        sizeSmall: {
          padding: '0.25rem 1rem',
          fontSize: '0.75rem',
        },
        sizeMedium: {
          padding: '0.5rem 1.125rem',
          fontSize: '0.875rem',
        },
        sizeLarge: {
          padding: '0.75rem 1.25rem',
          fontSize: '1rem',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          backdropFilter: 'blur(2px)',
        },
        paper: {
          borderRadius: '0.75rem',
          boxShadow: 'none',
          color: '#333',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '2rem',
          backgroundColor: '#fff',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          lineHeight: 1,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          padding: 4,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        inset: {
          paddingLeft: '1.25rem',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h5: {
          fontSize: '1.25rem',
          fontWeight: 700,
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#1e8ad2',
    },
  },
});
