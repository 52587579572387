import constants from '@/constants/definition';

export const dehydrateTokenToLocalStorage = (token) => {
  token && localStorage.setItem(constants.CDH_TOKEN_KEY, token);
};

export const rehydrateTokenFromLocalStorage = () => {
  return localStorage.getItem(constants.CDH_TOKEN_KEY) || null;
};

export const dehydrateRoleToLocalStorage = (roleId) => {
  localStorage.setItem('currentRole', roleId);
};

export const rehydrateRoleFromLocalStorage = () => {
  const roleId = parseInt(localStorage.getItem('currentRole'), 10);
  if (Number.isInteger(roleId) && roleId >= 0) {
    return roleId;
  } else {
    localStorage.removeItem('currentRole');
    return null;
  }
};