/* eslint-disable react/prop-types */
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

const NOT_FIRST_LOGIN = 'notFirstLogin';

export const YOUTUBE_VIDEO_ID = '3oPyXMEj5c8';

export default function TutorialVideo({ buttonProps }) {
  const isFirstLogin = !document.cookie.split('; ').find(c => c.startsWith(NOT_FIRST_LOGIN));
  const [open, setOpen] = useState(isFirstLogin);

  function handleClose() {
    if (isFirstLogin) {
      document.cookie = `${NOT_FIRST_LOGIN}=true; Secure`;
    }
    setOpen(false);
  }

  return (
    <>
      <Button onClick={() => setOpen(true)} {...buttonProps}>
        教學影片
      </Button>
      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        open={open}
        sx={{
          '.MuiDialog-paper': {
            aspectRatio: '16 / 9',
            backgroundColor: 'transparent',
          },
        }}
      >
        <iframe
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          referrerPolicy="strict-origin-when-cross-origin"
          src={`https://www.youtube.com/embed/${YOUTUBE_VIDEO_ID}`}
          title="Dashboard Hub 操作說明"
          style={{ border: 'none', height: '100%' }}
        />
      </Dialog>
    </>
  );
}
