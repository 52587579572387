export const HUB_TYPES = {
  Google: 'Google',
  LINE: 'LINE',
  Meta: 'Meta',
};

export const ADS_TYPES = {
  [HUB_TYPES.Google]: 'Google Ads',
  [HUB_TYPES.LINE]: 'LINE LAP',
  [HUB_TYPES.Meta]: 'Meta Ads',
};

export const REPORT_TYPES = {
  [HUB_TYPES.Google]: 'google_ads_sem',
  [HUB_TYPES.LINE]: 'line_lap',
  [HUB_TYPES.Meta]: 'facebook_ads',
};
